<template>
  <div class="grey lighten-3">
    <Loader v-if="isLoading && !eventIsNull" :size="64" />
    <div v-else>
      <v-navigation-drawer
        temporary
        v-model="drawer"
        right
        width="600"
        color="gray"
        style="position:fixed; top:0; right:0; overflow-y:scroll; z-index: 10001;"
      >
        <v-sheet dark class="pa-5 text-center">
          <p class="text-caption text-md-h5">
            Última Oferta <br />
            <ins id="CurrentBID"> {{ currentValue | money }} </ins> MXN
          </p>
          <v-btn class="mt-md-3" small text outlined @click="drawer=false">Cancelar</v-btn>
        </v-sheet>

        <v-list class="pa-md-5 justify-center aling-center text-center" v-if="smallBid > 0" >
          <span class="text-caption text-md-h5 justify-center aling-center text-center">
            <strong v-if="canMakeAnotherBid">Ofertar directo</strong>
            <strong v-else> Comprar Ahora</strong>
          </span>
          <v-row class="mt-5 justify-center" no-gutters>
            <v-col cols="12" sm="4">
              <v-btn
                v-if="smallBid > 0"
                class="ma-2"
                color="primary"
                dark
                small
                @click="addToCurrent(smallBid)"
              >
                <i class="icon-repeat"></i> + {{ smallBid | money }} MXN
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4">  
              <v-btn
                class="ma-2"
                color="#df2127"
                dark
                small
                @click="addToCurrent(smallBid * 2)"
              >
                <i class="icon-repeat"></i> + {{ smallBid * 2 | money }} MXN
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4">  
              <v-btn
                class="ma-2"
                color="#df2127"
                dark
                small
                @click="addToCurrent(smallBid * 3)"
              >
                <i class="icon-repeat"></i> + {{ smallBid * 3| money }} MXN
              </v-btn>
            </v-col>
            
          </v-row>
        </v-list>
        <v-divider></v-divider>
        <v-list class="pa-md-5 justify-center aling-center text-center" dense>
          <v-list-item v-if="canOpenOffer">
            <v-list-item-content>
              <v-list-item-title>
                <div class="widget quick-contact-widget clearfix">
                  <span class="text-caption text-md-h5 justify-center aling-center"><strong>Oferta Abierta</strong></span>
                </div>
              </v-list-item-title>
              <div class="form-process"></div>
              <vuetify-money
                v-model="openValue"
                v-bind:label="label"
                v-bind:readonly="readonly"
                v-bind:disabled="disabled"
                v-bind:clearable="clearable"
                v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                v-bind:options="options"
                v-bind:properties="properties"
                v-bind:persistent-hint="true"
              />
              <v-btn
                  class="mt-2"
                  color="primary"
                  :disabled="!(openValue > currentValue + 2000)"
                  @click="openBid(openValue)"
                >
                  <i class="icon-repeat"></i> Confirmar
                </v-btn>
              <div
                v-if="!(openValue > currentValue + 1998)"
              >
                <p class="text-caption text-md-h6 orange--text">
                  <strong>Atención</strong> <br/>
                  Tu oferta abierta deberá ser mayor por almenos <strong>$2,000.00 MXN</strong></p>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div v-if="!eventIsNull">
        <title-event
          v-if="!eventIsNull"
          :name="event.horse.name"
          :resume="event.horse.resume"
          :short-description="event.horse.shortDescription"
        />
        <navigation-event @offerNow="offerNow()" :show-offer="eventIsReady" v-if="event.horse.type === 1"/>
        <v-row class="mt-2 ma-5">
          <v-col cols="12" sm="12" md="8">
            <v-card>
              <v-card-title>
                <p class="text-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-center font-weight-bold">
                  Galería
                </p>
              </v-card-title>
              <v-card-text>
                <div v-if="event.horse.photos.length > 1">
                  <gallery-event :photos="event.horse.photos"/>
                </div>
                <div v-else>
                  <v-img                    
                    :src="event.horse.photos[0]"
                    max-height="500px"
                    contain
                  >
                  </v-img>
                </div>

                <badges-event v-if="!eventIsNull" :horse="event.horse" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-card>
              <v-card-title v-if="event.streamKey">
                <p
                  class="text-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-center font-weight-bold"
                >
                  Transmisión en vivo:
                </p>
              </v-card-title>
              <v-card-text v-if="event.streamKey">
                <youtubeVisor :video="event.streamKey" :small="true" />
              </v-card-text>
              <v-card-title v-if="event.open">
                <p
                  class="text-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-center font-weight-bold"
                >
                  Última oferta
                </p>
              </v-card-title>
              <v-card-text v-if="event.open && !eventFinished">
                <p
                  class="text-h5 text-sm-h4 text-md-h5 text-lg-h4 text-center font-weight-black"
                >
                  <ins id="CurrentBID">
                    {{ currentValue ? currentValue : 0 | money }}
                  </ins>
                  MXN
                </p>
                <div
                  v-if="!eventStarted && event && event.open"
                  class="text-center"
                >
                  <h3>termina en:</h3>
                  <countdown
                    v-if="event !== null"
                    :year="event.due.year"
                    :month="event.due.month"
                    :day="event.due.day"
                    :hour="event.due.hour"
                    :minute="event.due.minutes"
                  />
                </div>
                <div v-else class="text-center">
                  <h3>inicia en:</h3>
                  <countdown
                    :year="event.init.year"
                    :month="event.init.month"
                    :day="event.init.day"
                    :hour="event.init.hour"
                    :minute="event.init.minutes"
                  />
                </div>
                
                <div class="text-center" v-if="!eventStarted">
                  <v-btn
                    class="ma-2"
                    color="#df2127"
                    dark
                    v-if="!eventIsNull && event.horse.offerBuyNow"
                    @click="dialogConfirmBuy()"
                  >
                    Comprar ahora {{ buyNow ? buyNow : 0 | money }}
                  </v-btn>
                  <v-btn
                    v-if="canMakeAnotherBid"
                    class="ma-2"
                    dark
                    @click="offerNow()"
                  >
                    Ofertar Ahora
                  </v-btn>
                </div>
              </v-card-text>
              <div v-else class="text-center">
                <h4 class="primary white--text pa-2">Evento terminado</h4>

                <h6 v-if="!event.open">
                  <span v-if="event.firstPay"> Trato en proceso </span>
                  <span v-if="event.deal"> Trato cerrado</span>
                </h6>
              </div>
              <div class="divider divider-center">
                <i class="icon-circle-blank"></i>
              </div>
              <div class="ma-5" v-if="!event.salesman.ranch.default">
                <v-row justify="center" class="mx-auto">
                  <v-col cols="12" md="3" centered>
                    <v-img
                      contain
                      max-height="200"
                      max-width="200"
                      :src="event.salesman.ranch.image"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" md="7" class="justify-center">
                    <h5>Nombre: {{ event.salesman.ranch.name }}</h5>
                  </v-col>
                </v-row>
              </div>
              <div class="ma-5">
                <DetailInfo :items="detailList" />
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mx-0 mx-md-5" v-if="event.horse.type === 1">
          <v-col cols="12">
            <v-card>
              <v-row>
                <v-col cols="12" md="6">
                  <v-card-title>
                    <p
                      class="text-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-center font-weight-bold"
                    >
                      Genealogía
                    </p>
                  </v-card-title>
                  <v-card-text>
                    <v-row id="PEDIGREE">
                      <div v-if="loadingHDP">
                        <v-progress-circular
                          indeterminate
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <v-col cols="12" v-else>
                        <PedigreeTree
                          v-if="hdpData[0]"
                          :pedigreeTree="hdpData[0]"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card-title 
                    v-if="
                      !eventIsNull &&
                        event.horse.youtubeVideo.length > 0 &&
                        event.horse.youtubeVideo[0] !== ''
                    "
                  >
                    <p
                      class="text-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-center font-weight-bold"
                    >
                      Videos
                    </p>
                  </v-card-title>
                  <v-card-text>

                  <v-row
                    v-if="
                      !eventIsNull &&
                        event.horse.youtubeVideo.length > 0 &&
                        event.horse.youtubeVideo[0] !== ''
                    "
                  >
                    <v-col
                      cols="12"
                      v-for="video in event.horse.youtubeVideo"
                      :key="video"
                    >
                      <youtubeVisor :video="video" :small="false" />
                    </v-col>
                  </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-card-title class="text-center">
                    <p
                      class="text-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-center font-weight-bold"
                    >
                      Salud
                    </p>
                  </v-card-title>
                  <div id="SALUD" v-if="hdhData.length > 0" class="text-center mx-6">
                    <p
                      class="text-body-1 text-sm-subtitle-2 text-md-subtitle-1"
                    >
                      Observaciones adicionales:
                    </p>
                    <p
                      class="text-body-1 text-sm-subtitle-2 text-md-subtitle-1"
                    >
                      {{ hdhData[0].observations === '' ? 'Sin observaciones' : hdhData[0].observations}}
                    </p>
                    <HealthDetail
                      :healthDetail="hdhData[0]"
                      :key="event.horse._id"
                    />
                  </div>
                </v-col>
              </v-row>              
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-sheet
        class="that-bottom-bar d-lg-none d-xl-none"
        width="100%"
        dark
        v-if="event"
      >
        <v-row class="mx-auto justify-center aling-center">
          <v-col>
            <div>
              <v-sheet width="300px" class="text-center justify-center mx-auto">
                <strong>
                  Última Oferta
                  <ins id="CurrentBID">
                    {{ currentValue ? currentValue : 0 | money }}
                  </ins>
                  MXN
                </strong>
                <h3>
                  <strong>
                    <em v-if="!eventStarted">
                      Termina en:
                    </em>
                    <em v-else>
                      Inicia en:
                    </em>
                  </strong>
                </h3>
                <countdown
                  v-if="!eventStarted"
                  :year="event.due.year"
                  :month="event.due.month"
                  :day="event.due.day"
                  :hour="event.due.hour"
                  :minute="event.due.minutes"
                />
                <countdown
                  v-else
                  :year="event.init.year"
                  :month="event.init.month"
                  :day="event.init.day"
                  :hour="event.init.hour"
                  :minute="event.init.minutes"
                />
              </v-sheet>
            </div>
          </v-col>
        </v-row>
      </v-sheet>

      <v-dialog
        v-model="ConfirmBuy"
        persistent
        max-width="1500px"
        style="z-index: 1001;"
      >
        <v-card v-if="stepConfirm === 1" >
          <v-card-title>
            <span class="headline">Confirmación de compra</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row v-if="!eventIsNull" justify="center">
                <v-col
                  cols="12"
                >
                  <gallery-event
                    v-if="!eventIsNull && event.horse.photos > 1"
                    :photos="event.horse.photos"
                  />
                  <v-img
                    v-else
                    :src="event.horse.photos[0]"
                    max-height="500px"
                    contain
                  >

                  </v-img>
                </v-col>
                <v-col v-if="configData">
                  <h3>
                    Es requerido realizar un primer pago de
                    <strong>{{ configData.minBuy | money }}</strong>
                  </h3>
                  <p>
                    Este pago será considerado como un abono a la transacción y
                    deberá ser liquidado durante los siguientes
                    {{ configData.daysMulta }} días.
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions
            class="justify-end"
          >
            <v-btn
              color="black"
              text
              @click="
                ConfirmBuy = false;
                stepConfirm = 1;
              "
            >
              Cancelar
            </v-btn>
            <v-btn color="#df2127" text @click="nextStep()">
              Confirmar compra
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="stepConfirm === 2" max-height="800px">
          <v-card-title>
            <span class="headline">Procesemos tu pago</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <h3>Selecciona tu tarjeta</h3>
              <v-row>
                <payment-process 
                  v-if="configData"
                  currency="MXN"
                  itemName="Anticipo"
                  :itemNumber="1"
                  :itemQuantity="1"
                  :itemAmount="'' + configData.minBuy"
                  :numberOfItems="1"
                  :userTokenId="this.$session.get('person')"
                  :totalAmount="'' + configData.minBuy"
                  :first_name="this.currentSalesman.name"
                  :last_name="this.currentSalesman.last_name"
                  :address1="this.currentSalesman.address1"
                  :city="this.currentSalesman.city"
                  :zip="this.currentSalesman.zip"
                  :country="this.currentSalesman.country"
                  :phone1="this.currentSalesman.phone1"
                  :email="this.currentSalesman.email"
                  customField1="first_payment"
                />
                <!-- <payment-process
                  v-if="configData"
                  currency="MXN"
                  itemName="inscripcion"
                  :itemNumber="1"
                  :itemQuantity="1"
                  :itemAmount="'' + configData.minBuy"
                  :numberOfItems="1"
                  :userTokenId="this.$session.get('person')"
                  :totalAmount="'' + configData.minBuy"
                  customField1="first_payment"
                /> -->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-chip class="ma-git2" color="black" dark>
              <v-icon left>
                mdi-lock-check-outline
              </v-icon>
              Transaccion segura
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              text
              @click="
                ConfirmBuy = false;
                stepConfirm = 1;
              "
            >
              Cancelar
            </v-btn>
            <v-btn color="#df2127" text @click="process()">
              Procesar Compra
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-if="stepConfirm === 3"
          class="mx-auto justify-center aling-center"
        >
          <v-card-title>
            <span class="headline">Procesemos tu pago Correctamente</span>
          </v-card-title>
          <v-card-text class="mx-auto justify-center text-center">
            <img
              class="mx-auto justify-center aling-center"
              height="300"
              src="/images/dribbble-gif.gif"
              alt="payment"
            />
            <p class="mx-auto text-center">
              Las instrucciones llegarán a tu correo electrónico, y el vendedor
              te contactará para formalizar la entrega.
            </p>
            <p class="mx-auto justify-center aling-center">
              Durante estos días puedes solicitar toda la información que
              confirme los datos que incorporó el Vendedor.
            </p>
            <p class="mx-auto justify-center aling-center">
              Ante cualquier anomalía favor de contactar al equipo de
              <strong> soporte@ehorsesale.com</strong> .
            </p>
          </v-card-text>
          <v-btn color="#df2127" text @click="confirmBuyProcess()">
            Cerrar
          </v-btn>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import countdown from "@/components/userSide/CountDown.vue";
import EventBus from "../../services/events";
import PedigreeTree from "@/components/adminSide/PedigreeTree";
import PaymentProcess from "@/components/adminSide/PaymentProcess";
import Loader from "@/components/adminSide/Loader";
import DetailInfo from "@/components/userSide/DetailInfo";
import youtubeVisor from "@/components/adminSide/youtubeVisor";
import HealthDetail from "@/components/userSide/HealthDetail";

import TitleEvent from "@/components/userSide/horse/Title-Event";
import NavigationEvent from "@/components/userSide/horse/Navigation-Event";
import GalleryEvent from "@/components/userSide/horse/GalleryEvent";
import BadgesEvent from "@/components/userSide/horse/BadgesEvent.vue";

export default {
  created() {
    this.$socket.$subscribe("event_set_bid_in", (payload) => {
      if (payload.event === this.$route.params.event)
        this.currentValue = payload.currentBid;
    });
    this.$socket.$subscribe("user_pay_first", (payload) => {
      if (this.$session.get("person") === payload.userToken) {
        if (payload.status === "APPROVED" || payload.ppp_status === "OK") {
          this.canContinue = true;
        } else {
          this.response = payload.reason;
        }
        if (this.canContinue) {
          let bid;
          if (this.currentValue === null)
            this.currentValue = parseFloat(this.configData.minBuy);
          else this.currentValue = parseFloat(this.configData.minBuy);
          bid = {
            currentBid: this.currentValue,
            event: this.$route.params.event,
            _id: this.$route.params.event,
            user: this.$session.get("person"),
            bid: parseFloat(this.configData.minBuy),
            biddate: Date.now(),
          };
          this.$store.dispatch("homeState/makeBid", bid).then(() => {
            this.drawer = !this.drawer;
            this.$socket.client.emit("event_set_bid", bid);
            this.calculateNextOffer();
          });
          this.$store.dispatch("eventsModule/finishEvent", bid).then(() => {
            let eventBid = {
              event_id: this.$route.params.event,
              location: window.location.origin,
              photo: this.event.horse.photos[0],
            };
            this.$store.dispatch("emailModule/notifyFinish", eventBid);
          });
          this.stepConfirm = 3;
        }
      }
      if (this.event === null) {
        this.$store.dispatch(
          "homeState/fetchEventDetail",
          this.$route.params.event
        );
        this.$store.dispatch(
          "homeState/fetchLastBid",
          this.$route.params.event
        );
      }
    });
  },
  data: () => ({
    currentSalesman:{
          name: "Samuel",
          last_name: "Herrera",
          address1:"calle",
          city:"Chihuahua",
          zip:"31150",
          country:"MX",
          phone1:"6141763011",
          email:"soysam@zelin.studio"          
    },
    smallBid: 0,
    localPhotos: [],
    drawer: false,
    currentValue: 0,
    openValue: 0,
    label: "Venta estimada",
    readonly: false,
    disabled: false,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "es-MX",
      prefix: "$",
      suffix: "MXN",
      length: 7,
      precision: 0,
    },
    properties: {
      counter: "7",
      hint: "Agrega tu oferta",
    },
    canOpenOffer: true,
    canMakeAnotherBid: true,
    timeToBuyNow: false,
    loadingHDP: false,
    loadingHDH: false,
    ConfirmBuy: false,
    stepConfirm: 1,
    canContinue: false,
    response: "",
    detailList: [
      {
        icon: "lock-check-outline",
        title: "Transacciones seguras",
        subtitle: "Basados en estandares de seguridad.",
      },
      {
        icon: "credit-card-settings-outline",
        title: "Opciones de pago",
        subtitle: "Aceptamos Visa, MasterCard, Paypal y American Express.",
      },
      {
        icon: "truck",
        title: "Envíos",
        subtitle: "Solicita información sobre transportación.",
      },
      {
        icon: "backup-restore",
        title: "Devoluciones de 7 días",
        subtitle: "Consulta términos y condiciones.",
      },
      {
        icon: "shield-check-outline",
        title: "Auditado",
        subtitle: "eHorseSale Auditó este caballo y su información",
      },
    ],
  }),
  computed: {
    eventIsReady() {
      return (
        this.event.open &&
        !this.eventFinished &&
        this.event.open && !this.eventStarted
      );
    },
    eventIsNull() {
      return this.event === null;
    },
    eventStarted() {
      return this.event ? new Date(this.event.initialDate) > Date.now() : false;
    },
    eventFinished() {
      return new Date(this.event.finishDate) < Date.now();
    },
    isLogged() {
      return this.$session.exists();
    },
    buyNow() {
      return this.currentValue > this.event.horse.buyNow * 0.8
        ? // this.currentValue * 1.20
          this.event.horse.buyNow
        : this.event.horse.buyNow;
    },
    isLoading() {
      return (
        this.loading &&
        this.loadingConfig &&
        this.loadinghdt &&
        this.loadinghdh &&
        this.loadinghdp
      );
    },
    ...mapState({
      loading: (state) => state.homeState.loadingStatus,
      loadingConfig: (state) => state.homeState.loadingConfig,
      loadinghdt: (state) => state.hdtModule.loadingStatus,
      loadinghdh: (state) => state.hdhModule.loadingStatus,
      loadinghdp: (state) => state.hdpModule.loadingStatus,
      configData: (state) => state.homeState.configData,
      event: (state) => state.homeState.event,
      hdhData: (state) => state.hdhModule.data,
      hdpData: (state) => state.hdpModule.data,
      currentPrice: (state) => state.homeState.currentPrice,
    }),
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.$store.dispatch(
      "homeState/fetchEventDetail",
      this.$route.params.event
    );
    await this.$store.dispatch(
      "homeState/fetchLastBid",
      this.$route.params.event
    );
    await this.$store.dispatch("hdhModule/fetchByMaster", this.event.horse._id);
    await this.$store.dispatch("hdpModule/fetchByMaster", this.event.horse._id);
    this.currentValue = this.event.currentBid;
    this.calculateNextOffer();
    EventBus.$on("event-bid-set", () => {
      if (this.currentPrice != 0) this.currentValue = this.currentPrice;
    });
    EventBus.$on("event-hdp-set", () => {
      if (this.hdpData.length > 0)
        this.event.horse.photos.push(this.hdpData[0].file);
    });
    EventBus.$on("bid-created", (item) => {
      this.currentValue = item.currentBid;
      let eventBid = {
        event_id: this.$route.params.event,
        location: window.location.origin,
        photo: this.event.horse.photos[0],
      };
      this.$store.dispatch("emailModule/notifyBid", eventBid);
    });
  },
  components: {
    countdown,
    PedigreeTree,
    PaymentProcess,
    Loader,
    DetailInfo,
    youtubeVisor,
    HealthDetail,
    TitleEvent,
    NavigationEvent,
    GalleryEvent,
    BadgesEvent,
  },
  methods: {
    async confirmBuyProcess() {
      this.ConfirmBuy = false;
      this.stepConfirm = 1;
      this.$store.dispatch(
        "homeState/fetchEventDetail",
        this.$route.params.event
      );
      await this.buyNowBid(this.event.horse.buyNow);
      await this.$store.dispatch(
        "homeState/fetchLastBid",
        this.$route.params.event
      );
    },
    offerNow() {
      if (!this.$session.exists()) {
        alert("inicia Sesión para continuar");
        this.$router.push({ name: "login-us" });
      } else {
        if (this.$session.get("type") != "FRN") {
          alert("vendedores y administadores no pueden pujar");
        } else {
          this.drawer = !this.drawer;
        }
      }
    },
    async addToCurrent(value) {
      let bid;
      if (this.currentValue !== null)
        this.currentValue = this.currentValue + parseFloat(value);
      else this.currentValue = parseFloat(value);
      bid = {
        currentBid: this.currentValue,
        event: this.$route.params.event,
        user: this.$session.get("person"),
        bid: this.currentValue,
        biddate: Date.now(),
      };

      await this.$store.dispatch("homeState/makeBid", bid).then(() => {
        this.drawer = !this.drawer;
        this.$socket.client.emit("event_set_bid", bid);
        this.calculateNextOffer();
      });
    },
    async openBid(value) {
      let bid;
      bid = {
        currentBid: parseFloat(value),
        event: this.$route.params.event,
        user: this.$session.get("person"),
        bid: parseFloat(value),
        biddate: Date.now(),
      };

      await this.$store.dispatch("homeState/makeBid", bid).then(() => {
        this.drawer = !this.drawer;
        this.$socket.client.emit("event_set_bid", bid);
        this.calculateNextOffer();
      });
    },
    async buyNowBid(value) {
      let bid;
      bid = {
        currentBid: parseFloat(value),
        event: this.$route.params.event,
        user: this.$session.get("person"),
        bid: parseFloat(value),
        biddate: Date.now(),
        horse: this.event.horse.name,
      };

      this.$store.dispatch("peopleModule/updateBank", this.$session.get("person"));

      await this.$store.dispatch("homeState/buyNowBid", bid).then(async () => {
        this.$socket.client.emit("event_set_bid", bid);
        this.calculateNextOffer();
        let eventBid = {
          event_id: this.$route.params.event,
          location: window.location.origin,
          photo: this.event.horse.photos[0],
        };
        await this.$store.dispatch("emailModule/notifyFinish", eventBid);
        await this.$store.dispatch("emailModule/notifySalesMan", eventBid);
        await this.$store.dispatch("emailModule/SendStepsBuyer", eventBid);
      });
    },
    addOpenToCurrent() {
      this.currentValue = parseFloat(this.openValue);
      this.openValue = 0;
    },
    goToRegist() {
      this.$router.push({ name: "register-us" });
    },
    goToLogIn() {
      this.$router.push({ name: "login-us" });
    },
    calculateNextOffer() {
      this.openValue = 0;
      if (this.currentValue > this.event.horse.buyNow * 0.95) {
        this.smallBid = this.event.horse.buyNow;
        this.canOpenOffer = false;
        this.canMakeAnotherBid = false;
      } else {
        this.smallBid = this.currentValue * 0.1;
        this.smallBid = Math.ceil(this.smallBid / 1000) * 1000;
      }
      // if(this.currentValue > this.event.horse.buyNow)
      //   {
      //      this.event.horse.buyNow = this.currentValue
      //   } 
    },
    dialogConfirmBuy() {
      if (!this.$session.exists()) {
        alert("inicia Sesión para continuar");
        this.$router.push({ name: "login-us" });
      } else {
        if (this.$session.get("type") != "FRN") {
          alert("vendedores y administadores no pueden pujar");
        } else {
          this.ConfirmBuy = true;
        }
      }
    },
    nextStep() {
      this.stepConfirm = this.stepConfirm + 1;
    },
    process() {
      this.stepConfirm = this.stepConfirm + 1;
    },
  },
  destroyed() {
    EventBus.$off("bid-created");
    EventBus.$off("event-hdp-set");
    EventBus.$off("event-hdh-set");
  },
};
</script>

<style>
.that-bar {
  position: sticky;
  top: 0px;
  z-index: 1000;
}

.that-bottom-bar {
  position: sticky;
  bottom: 0px;
  z-index: 1000;
}

.that-bar-link {
  text-decoration: none !important;
}

.ul-list {
  list-style-type: none;
}
</style>
